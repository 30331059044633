import { useGetBalance, useUpdateBalance } from './useBalanceAccounts';
import { useBulkInviteUpload } from './useBulkInviteUpload';
import { useBusinessCards } from './useBusinessCards';
import {
  useAddCommunity,
  useCommunities,
  useCommunity,
  useDeleteCommunity,
  useEditCommunity,
} from './useCommunitites';
import { useConfiguration, useEditConfiguration } from './useConfiguration';
import {
  useAddDriverBalance,
  useAddDriversLicense,
  useDataProtection,
  useDriver,
  useDrivers,
  useEditDriver2FA,
  useEditDriverDashboardAccess,
  useEditDriverDetails,
  useEditDriverOrganistaion,
  useEditDriverPaymentInstrument,
  useEditDriverPersonalInformation,
  useEditDriverVerified,
  useEditProviderMeta,
  useExportDrivers,
  useRemitDriverInvoices,
  useSendDriverInvite,
  useSyncGreenwheels,
} from './useDrivers';
import { useFleets } from './useFleets';
import { useExportInvitations, useInvitations } from './useInvitations';
import { useEditMerchantCategoryCodes, useMerchantCategoryCodes } from './useMerchantCategoryCodes';
import { useAddMetaRule, useDeleteMetaRule, useEditMetaRule, useMetaRules } from './useMetaRules';
import {
  useAddOrganisation,
  useAddUser,
  useDownloadBulkInvites,
  useEditAFAS,
  useEditOrganisation,
  useExportOrganisation,
  useHasBulkInvites,
  useOrganisation,
  useOrganisations,
  useResendInvites,
  useUpdateOrganisationCommissions,
  useUpdateOrganisationProviders,
} from './useOrganisations';
import { useAddProduct, useEditProduct, useProduct, useProducts, useVAT } from './useProducts';
import { useSendPushNotifications } from './usePushNotifications';
import {
  useCancelReservation,
  useCloseReservation,
  useExportReservations,
  useInvoiceReservation,
  useLockVehicle,
  useReservation,
  useReservations,
  useUnlockVehicle,
} from './useReservations';
import {
  useOrganisationStatistics,
  useOrganisationStatisticsCSV,
  useProviderStatistics,
  useReceiptStatistics,
  useReservationStatistics,
  useRevenueStatistics,
  useUserStatistics,
} from './useStatistics';
import { useAddToOrganisation, useSuperAdmins } from './useSuperAdmin';
import {
  useDownloadInvoice,
  useEditTransaction,
  useResetTransaction,
  useTransaction,
  useTransactions,
} from './useTransactions';
import { useTravelCards, useUpdateTravelCardStatus } from './useTravelCard';
import {
  useAddVoucher,
  useDeleteVoucher,
  useEditVoucher,
  useVoucher,
  useVouchers,
} from './useVouchers';

const UseQuery = {
  configuration: useConfiguration,
  transaction: useTransaction,
  transactions: useTransactions,
  superAdmins: useSuperAdmins,
  metaRules: useMetaRules,
  receiptStatistics: useReceiptStatistics,
  userStatistics: useUserStatistics,
  providerStatistics: useProviderStatistics,
  organisation: useOrganisation,
  organisations: useOrganisations,
  reservationStatistics: useReservationStatistics,
  revenueStatistics: useRevenueStatistics,
  drivers: useDrivers,
  driver: useDriver,
  vouchers: useVouchers,
  voucher: useVoucher,
  reservation: useReservation,
  reservations: useReservations,
  travelCards: useTravelCards,
  businessCards: useBusinessCards,
  product: useProduct,
  products: useProducts,
  VAT: useVAT,
  merchantCategoryCodes: useMerchantCategoryCodes,
  community: useCommunity,
  communities: useCommunities,
  fleets: useFleets,
  invitations: useInvitations,
  hasBulkInvites: useHasBulkInvites,
  organisationStatistics: useOrganisationStatistics,
};

const UseMutation = {
  addDriverBalance: useAddDriverBalance,
  editConfiguration: useEditConfiguration,
  editTransaction: useEditTransaction,
  resetTransaction: useResetTransaction,
  downloadInvoice: useDownloadInvoice,
  addMetaRule: useAddMetaRule,
  deleteMetaRule: useDeleteMetaRule,
  useEditMetaRule: useEditMetaRule,
  updateTravelCardStatus: useUpdateTravelCardStatus,
  addVoucher: useAddVoucher,
  editVoucher: useEditVoucher,
  deleteVoucher: useDeleteVoucher,
  exportDrivers: useExportDrivers,
  syncGreenwheels: useSyncGreenwheels,
  cancelReservation: useCancelReservation,
  closeReservation: useCloseReservation,
  lockVehicle: useLockVehicle,
  unlockVehicle: useUnlockVehicle,
  addProduct: useAddProduct,
  editProduct: useEditProduct,
  dataProtection: useDataProtection,
  editMerchantCategoryCodes: useEditMerchantCategoryCodes,
  editOrganisation: useEditOrganisation,
  addSuperAdminsToOrganisation: useAddToOrganisation,
  exportOrganisation: useExportOrganisation,
  resendInvites: useResendInvites,
  editAFAS: useEditAFAS,
  addUser: useAddUser,
  sendPushNotifications: useSendPushNotifications,
  getBalance: useGetBalance,
  updateBalance: useUpdateBalance,
  editCommunity: useEditCommunity,
  addCommunity: useAddCommunity,
  deleteCommunity: useDeleteCommunity,
  editDriverOrganisation: useEditDriverOrganistaion,
  editDriverPersonalInformation: useEditDriverPersonalInformation,
  editDriver2FA: useEditDriver2FA,
  editDriverDashboardAccess: useEditDriverDashboardAccess,
  editDriverDetails: useEditDriverDetails,
  editDriverPaymentInstrument: useEditDriverPaymentInstrument,
  addDriversLicense: useAddDriversLicense,
  editDriverVerified: useEditDriverVerified,
  editProviderMeta: useEditProviderMeta,
  sendDriverInvite: useSendDriverInvite,
  exportReservations: useExportReservations,
  addOrganisation: useAddOrganisation,
  exportInvitations: useExportInvitations,
  remitDriverInvoices: useRemitDriverInvoices,
  invoiceReservation: useInvoiceReservation,
  downloadBulkInvites: useDownloadBulkInvites,
  bulkInviteUpload: useBulkInviteUpload,
  updateOrganisationCommissions: useUpdateOrganisationCommissions,
  updateOrganisationProviders: useUpdateOrganisationProviders,
  exportOrganisationStatistics: useOrganisationStatisticsCSV,
};

export { UseMutation, UseQuery };
